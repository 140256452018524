<template>
  <div>
    <div class="d-flex">
      <v-switch
        v-model="showAppointed"
        :label="showAppointedLabelText"
        class="mr-8"
        :disabled="hasNoAppointed"
      ></v-switch>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Искать по экспертам"
        single-line
        hide-details
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="displayExperts"
      hide-default-footer
      disable-pagination
      item-key="id"
      show-select
      :search="search"
    >
      <!-- Убрал чекбокс выбора всех причин -->
      <template v-slot:[`header.data-table-select`]=""> &nbsp; </template>

      <!-- Создаю чекбоксы вручную и без привязки к v-model чтобы отслеживать клики и обрабатывать их самостоятельно -->
      <template v-slot:[`item.data-table-select`]="{ item }"
        ><v-checkbox
          v-if="clinRec.editorAccess"
          :input-value="item.selected"
          readonly
          @click="onSelectExpert(item)"
      /></template>

      <template v-slot:[`item.controls`]="{ item }">
        <div class="d-flex controls-local">
          <v-btn
            v-if="item.selected"
            @click="openAnswers(item)"
            fab
            small
            depressed
            color="transparent"
            ><v-icon>mdi-note-search-outline</v-icon></v-btn
          >
        </div>
      </template>
    </v-data-table>

    <DeleteDialog
      title="Отменить назначение?"
      description="Если эксперт уже начал работу над анкетой его ответы пропадут."
      deleteText="Да, отменить"
      cancelText="Оставить"
      ref="deleteDialog"
    />

    <ReadyAnswersDialog ref="readyAnswersDialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import ReadyAnswersDialog from '@/components/dialogs/ReadyAnswersDialog.vue'
import { isQuestionDone } from '@/components/helpers'
import {
  questionnaireStatuses,
  getStatus,
} from '@/store/const/questionnaireStatuses'
import {
  YES_ANSWER_ID,
  NO_ANSWER_ID,
  NOT_MY_ANSWER_ID,
} from '@/store/const/answers'

export default {
  data() {
    return {
      search: '',
      showAppointed: false,
    }
  },
  props: {
    experts: {
      type: Array,
      required: true,
    },
    clinRec: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.showAppointed = this.clinRec.experts.length
  },
  watch: {
    clinRec: {
      deep: true,
      handler() {
        if (this.hasNoAppointed) {
          this.showAppointed = false
        }
      },
    },
  },
  components: {
    DeleteDialog,
    ReadyAnswersDialog,
  },
  computed: {
    ...mapGetters(['getQuestionnaires']),
    showAppointedLabelText() {
      return this.appointedLength
        ? `Показать только тех, на кого назначена (${this.appointedLength})`
        : 'Нет назначенных экспертов'
    },
    appointedLength() {
      return this.clinRec.experts.length
    },
    hasNoAppointed() {
      return Boolean(!this.appointedLength)
    },
    displayExperts() {
      let experts = this.experts.filter(expert => expert.canAnswer)
      experts.forEach(expert => {
        expert.selected = this.isAppointed(expert)
        if (expert.selected) {
          this.addStatisiticToExpert(expert)
        }
      })
      return this.showAppointed
        ? experts.filter(expert => expert.selected)
        : experts
    },
    headers() {
      return [
        { text: 'Выберете Экспертов для этой КР', value: 'fio', width: 300 },
        { text: 'Статус', value: 'readableStatus', width: 160 },
        {
          text: `Всего ответов (из ${this.clinRec.questionnairyLength})`,
          value: 'doneAnswers',
        },
        { text: '«Да»', value: 'yesAnswes' },
        { text: '«Не профильный»', value: 'notMyAnswer' },
        { text: '«Нет»', value: 'noAnswer' },
        { text: '', value: 'controls', sortable: false, width: 75 },
      ]
    },
  },
  methods: {
    ...mapActions(['addQuestionnaire', 'deleteQuestionnaire']),
    onSelectExpert(item) {
      if (this.isAppointed(item)) {
        this.cancelAppointExpert(this.clinRec.id, item.id)
      } else {
        this.appointExpert(
          this.clinRec.id,
          item.id,
          this.clinRec.questionnairyLength
        )
      }
    },
    addStatisiticToExpert(expert) {
      const questionnaire = this.getQuestionnaires.find(
        q => q.cr_id === this.clinRec.id && q.user_id === expert.id
      )
      const answers = questionnaire.data

      expert.doneAnswers = 0
      expert.yesAnswes = 0
      expert.noAnswer = 0
      expert.notMyAnswer = 0

      answers.forEach(a => {
        expert.doneAnswers += isQuestionDone(a)
        expert.yesAnswes += a.answerId === YES_ANSWER_ID
        expert.noAnswer += a.answerId === NO_ANSWER_ID
        expert.notMyAnswer += a.answerId === NOT_MY_ANSWER_ID
      })
      expert.status = getStatus(
        questionnaire.was_sent,
        expert.doneAnswers,
        answers.length
      )
      expert.readableStatus = questionnaireStatuses[expert.status].textForAdmin
    },
    isAppointed(item) {
      return this.clinRec.experts.includes(item.id)
    },
    appointExpert(cr_id, user_id, length) {
      this.addQuestionnaire({ cr_id, user_id, length })
    },
    async cancelAppointExpert(crId, userId) {
      if (await this.$refs.deleteDialog.open()) {
        const questionnaire = this.getQuestionnaires.find(
          q => q.cr_id === crId && q.user_id === userId
        )
        this.deleteQuestionnaire(questionnaire.id)
      }
    },
    openAnswers(item) {
      this.$refs.readyAnswersDialog.open(item, this.clinRec)
    },
  },
}
</script>

<style scoped>
/*
  Захардкоженный класс controls-local нужен, чтобы корректно работали ховеры в таблице вложенной в таблицу
  Из-за этого же пока не получается вытащить это в отдельный компонент
*/
.v-data-table >>> tr .controls-local {
  display: none !important;
}
.v-data-table >>> tr:hover .controls-local {
  display: flex !important;
}
.v-input--switch {
  width: 400px;
}
</style>
