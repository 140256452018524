<template>
  <Page>
    <v-tabs @change="onChange">
      <v-tab v-for="tab in tabs" :key="tab.name"> {{ tab.displayName }} </v-tab>
      <v-spacer />
      <AdminPageControls :tabName="selectedTabName" />
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <component
          :is="tab.component"
          :experts="tab.experts"
          :clinRecs="tab.clinRecs"
          :hideForAnalyst="tab.hideForAnalyst"
        />
      </v-tab-item>
    </v-tabs>
  </Page>
</template>

<script>
import Page from '@/components/layout/Page.vue'
import UsersList from '@/components/admin/UsersList.vue'
import ClinRecsList from '@/components/admin/ClinRecsList.vue'
import AdminPageControls from '@/components/admin/AdminPageControls.vue'

import { mapGetters, mapActions } from 'vuex'

import { EXPERT, ANALYST, USER_ROLES } from '@/store/const/userRoles'
import {
  TAB_CLIN_REC,
  TAB_MY_CLIN_REC,
  TAB_USERS,
} from '@/store/const/adminTabs'
import { questionsList } from '@/store/const/questionsList'

export default {
  data() {
    return {
      selectedTabName: '',
    }
  },
  components: {
    UsersList,
    ClinRecsList,
    AdminPageControls,
    Page,
  },
  name: 'Admin',
  computed: {
    ...mapGetters([
      'getUsers',
      'getClinRecs',
      'getQuestionnaires',
      'getLoggedUserId',
      'isAdmin',
      'isAnalyst',
    ]),
    tabs() {
      // Массив задает порядок вкладок
      // Возможно переделать на объект с ключом по имени вкладки и полем с расположением
      let tabs = [
        {
          name: TAB_CLIN_REC,
          displayName: `КР (${this.preparedClinRecs.length})`,
          showOnlyForAnalist: false,
          component: 'ClinRecsList',
          experts: this.preparedExperts,
          clinRecs: this.preparedClinRecs,
        },
        {
          name: TAB_MY_CLIN_REC,
          displayName: `Мои КР (${this.myPreparedClinRecs.length})`,
          showOnlyForAnalist: true,
          component: 'ClinRecsList',
          experts: this.preparedExperts,
          clinRecs: this.myPreparedClinRecs,
          hideForAnalyst: true,
        },
        {
          name: TAB_USERS,
          displayName: `${this.isAnalyst ? 'Эксперты' : 'Пользователи'} (${
            this.preparedExperts.length
          })`,
          showOnlyForAnalist: false,
          component: 'UsersList',
          experts: this.preparedExperts,
          clinRecs: this.preparedClinRecs,
        },
      ]
      if (!this.isAnalyst) {
        tabs = tabs.filter(t => t.showOnlyForAnalist === false)
      }
      return tabs
    },
    preparedExperts() {
      const experts = this.getUsers.map(user => ({
        ...user,
        ...this.getUserRoleParams(user),
        ...this.getUserClinRecs(user),
        editorAccess: this.hasEditorAccessTo(user),
        ownerFIO: this.getAnalystFio(user),
      }))
      return this.isAnalyst ? experts.filter(ex => ex.role === EXPERT) : experts
    },
    preparedClinRecs() {
      return this.getClinRecs.map(cr => ({
        ...cr,
        ...this.prepareExpertsAndTotalDoneToClinRec(cr),
        questionnairyLength: this.getQuestionnairyLength(cr.profile),
        editorAccess: this.hasEditorAccessTo(cr),
        analyst: this.getAnalystFio(cr),
      }))
    },
    myPreparedClinRecs() {
      return this.preparedClinRecs.filter(
        cr => cr.user_owner_id === this.getLoggedUserId
      )
    },
  },
  methods: {
    ...mapActions([
      'loadUsers',
      'loadLoggedUser',
      'loadClinRecs',
      'loadQuestionnaires',
      'loadFiles',
      'resetFilter',
    ]),
    getUserClinRecs(user) {
      const clinRecs = []
      if (user.role === EXPERT) {
        this.getQuestionnaires.forEach(q => {
          if (q.user_id === user.id) {
            clinRecs.push(q.cr_id)
          }
        })
      }
      if (user.role === ANALYST) {
        this.getClinRecs.forEach(cr => {
          if (cr.user_owner_id === user.id) {
            clinRecs.push(cr.id)
          }
        })
      }
      return { clinRecs, amountClinRecs: clinRecs.length }
    },
    getUserRoleParams(user) {
      return USER_ROLES.find(r => r.value === user.role)
    },
    getQuestionnairyLength(profile) {
      if (!questionsList[profile]) return 1
      return questionsList[profile].length
    },
    prepareExpertsAndTotalDoneToClinRec(cr) {
      const experts = []
      let isTotalDone = true
      this.getQuestionnaires.forEach(q => {
        if (q.cr_id === cr.id) {
          experts.push(q.user_id)
          isTotalDone = q.was_sent && isTotalDone
        }
      })
      if (experts.length === 0) {
        isTotalDone = false
      }
      return { experts, isTotalDone }
    },
    getAnalystFio(item) {
      const analyst = this.getUsers.find(a => a.id === item.user_owner_id)
      return analyst ? analyst.fio : 'Нет ответственного'
    },
    hasEditorAccessTo(item) {
      return this.isAdmin || item.user_owner_id === this.getLoggedUserId
    },
    onChange(tabIndex) {
      this.setTabName(tabIndex)
      this.resetFilter()
    },
    setTabName(tabIndex) {
      this.selectedTabName = this.tabs[tabIndex].name
    },
  },
  created() {
    this.loadUsers()
    this.loadClinRecs()
    this.loadQuestionnaires()
    this.loadFiles()
    this.loadLoggedUser()
    this.setTabName(0)
  },
}
</script>
