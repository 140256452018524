<template>
  <v-select
    v-if="isAdmin"
    label="Роль"
    :items="roles"
    :value="value"
    item-text="roleName"
    item-value="value"
    @change="onChange"
  />
</template>

<script>
import { USER_ROLES } from '@/store/const/userRoles'
import { mapGetters } from 'vuex'

export default {
  props: ['value'],
  data() {
    return {
      roles: [],
    }
  },
  created() {
    this.roles = USER_ROLES
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
