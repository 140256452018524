<template>
  <v-select
    label="Аналитик ЦЭККМП"
    :value="value"
    :items="getAnalyst"
    item-text="fio"
    item-value="id"
    @change="onChange"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['value'],
  computed: {
    ...mapGetters(['getAnalyst']),
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
