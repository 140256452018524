<template>
  <v-dialog v-model="show" max-width="620" @click:outside="closeDialog">
    <v-card>
      <v-card-title>{{ actionName }}&nbsp;{{ withWhoAction }}</v-card-title>
      <v-card-text>
        <v-text-field
          label="ФИО"
          v-model="user.fio"
          :rules="[rules.required]"
          validate-on-blur
          ref="fio"
        ></v-text-field>
        <SelectRole v-model="user.role" />
        <SelectOwner v-if="showOwner" v-model="user.user_owner_id" />
        <v-text-field
          label="Электронная почта"
          v-model="user.email"
          :rules="[rules.required, rules.email]"
          validate-on-blur
          ref="email"
        ></v-text-field>
        <v-text-field label="Телефон (в любом формате)" v-model="user.phone">
        </v-text-field>
        <v-textarea label="Комментрий" v-model="user.comment"> </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onAdd" color="primary"> {{ actionName }} </v-btn>
        <v-btn v-if="showResetPasswordButton" @click="resetPassword"
          >Сбросить пароль</v-btn
        >
        <v-btn @click="closeDialog">Отменить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EXPERT } from '@/store/const/userRoles'
import SelectRole from '@/components/dialogs/SelectRole'
import SelectOwner from '@/components/dialogs/SelectOwner.vue'

export default {
  components: {
    SelectRole,
    SelectOwner,
  },
  data() {
    return {
      user: {},
      USER_TEMPLATE: {
        fio: '',
        role: EXPERT,
        email: '',
        phone: '',
        comment: '',
        user_owner_id: 0,
      },
      /*
        Возможно програмно получать список ключей рефов
        Но возможно это не правильно. Если появятся другие рефы, которые не надо будет обрабатывать как поля формы
      */
      refsForValidate: ['fio', 'email'],
      rules: {
        required: v => !!v || 'Обязательное поле',
        email: v => {
          // Регулярка для валидации email. Источник — пример из документации vue к rules для v-input
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Неправильный адрес почты.'
        },
      },
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      require: false,
    },
  },
  computed: {
    ...mapGetters(['getLoggedUserRole', 'isAdmin', 'isAnalyst']),
    actionName() {
      return this.edit ? 'Редактировать' : 'Добавить'
    },
    withWhoAction() {
      return this.isAdmin ? 'пользователя' : 'эксперта'
    },
    showOwner() {
      return this.isAdmin && this.user.role === EXPERT
    },
    showResetPasswordButton() {
      return this.isAdmin && this.edit
    },
  },
  watch: {
    item() {
      if (this.item) {
        this.user = { ...this.item }
      }
    },
  },
  created() {
    this.user = { ...this.USER_TEMPLATE }
  },
  methods: {
    ...mapActions(['addUser', 'editUser']),
    closeDialog() {
      this.refsForValidate.forEach(ref => this.$refs[ref].reset())
      this.user = { ...this.USER_TEMPLATE }
      this.$emit('close-dialog')
    },
    resetPassword() {
      this.$emit('reset-password', this.item)
      this.$emit('close-dialog')
    },
    validate() {
      let totalValid = true
      for (let refKey of this.refsForValidate) {
        this.$refs[refKey].validate()
        if (!this.$refs[refKey].valid) {
          this.$refs[refKey].focus()
          this.$refs[refKey].blur()
          totalValid = false
          break
        }
      }
      return totalValid
    },
    setOwner() {
      if (this.isAnalyst || !this.user.user_owner_id) {
        this.user.user_owner_id = this.getLoggedUserId
      }
    },
    onAdd() {
      if (!this.validate()) return
      this.setOwner()
      if (this.edit) {
        this.editUser({ ...this.user, id: this.item.id })
      } else {
        this.addUser({ ...this.user })
      }
      this.closeDialog()
    },
  },
}
</script>
