<template>
  <div>
    <v-data-table
      :items="tableData"
      :headers="headers"
      hide-default-footer
      disable-pagination
      class="mb-8"
    >
      <template v-slot:[`item.files`]="{ item }">
        <div v-if="item.files.length">
          <FileList :files="item.files" />
        </div>
        <div v-else>—</div>
      </template>

      <template v-slot:[`item.isRelevant`]="{ item }">
        <div
          class="is-relevant-area"
          :class="{ [`is-relevant-area--avaliable`]: isAnalyst }"
          @click="changeRelevant(item)"
        >
          <v-icon v-if="item.isRelevant" color="green"
            >mdi-checkbox-marked-circle</v-icon
          >
          <!-- item.isRelevant — nullable -->
          <v-icon v-else-if="item.isRelevant === false" color="red"
            >mdi-cancel</v-icon
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { typograpf } from '@/components/helpers'
import { questionsList } from '@/store/const/questionsList'
import { answers } from '@/store/const/answers'
import FileList from '@/components/cards/FileList.vue'

export default {
  components: { FileList },
  props: {
    expertId: {
      type: Number,
      required: true,
    },
    clinRecId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: '№', value: 'number', sortable: false },
        { text: 'Вопрос', value: 'title', sortable: false },
        {
          text: 'Ответ эксперта',
          value: 'answer',
          sortable: false,
          width: 160,
        },
        {
          text: 'Ответ релевантен?',
          value: 'isRelevant',
          sortable: false,
          width: 160,
        },
        { text: 'Файлы', value: 'files', sortable: false },
        { text: 'Комментрий', value: 'comment', sortable: false },
      ],
    }
  },
  mounted() {
    this.loadClinRecById(this.clinRecId)
  },
  computed: {
    ...mapGetters([
      'getQuestionnaires',
      'getFiles',
      'getClinRecs',
      'getUsers',
      'isAnalyst',
      'getLoggedUserId',
      'getCurrentClinRecProfile',
    ]),
    tableData() {
      if (!questionsList[this.getCurrentClinRecProfile]) return
      const qList = questionsList[this.getCurrentClinRecProfile].slice()
      // Переписать на map
      qList.forEach(q => {
        q.title = typograpf(q.title)
        q.number = q.id + 1
        const question = this.questions.find(que => que.id === q.id)
        if (!question) return []
        q.answer = question.answerId
          ? answers.find(a => a.id === question.answerId).text
          : 'Нет ответа'
        q.comment = question.comment
        q.files = this.getFiles
          .slice()
          .filter(f => question.filesIds.includes(f.id))
        q.isRelevant = question.isRelevant
      })
      return qList
    },
    questionnaire() {
      const result = this.getQuestionnaires.find(
        q => q.cr_id == this.clinRecId && q.user_id == this.expertId
      )
      const EMPTY_TEMPLATE = {
        data: [],
        id: null,
        was_sent: null,
      }
      return result || EMPTY_TEMPLATE
    },
    questions() {
      return this.questionnaire.data
    },
    clinRecName() {
      return this.getClinRecs.slice().find(cr => cr.id === this.clinRecId).name
    },
    clinRecOwnerId() {
      return this.getClinRecs.slice().find(cr => cr.id === this.clinRecId)
        .user_owner_id
    },
    expertFIO() {
      return this.getUsers.slice().find(ex => ex.id === this.expertId).fio
    },
  },
  methods: {
    ...mapActions(['editQuestionnaire', 'loadClinRecById']),
    isChangeRelevantAlowed() {
      if (!this.isAnalyst) {
        this.$toast.warning('Только аналитик может оценивать ответы')
        return false
      }
      if (this.isAnalyst && this.clinRecOwnerId !== this.getLoggedUserId) {
        this.$toast.warning(
          'Оценить ответы можно только для КР у которой вы ответственный'
        )
        return false
      }
      if (!this.questionnaire.was_sent) {
        this.$toast.warning(
          'Оценить ответы можно только после того, как эксперт отправит анкету'
        )
        return false
      }
      return true
    },
    changeRelevant({ id }) {
      if (!this.isChangeRelevantAlowed()) return
      const currentRelevanсe = this.questions[id].isRelevant
      const nextRelevance = currentRelevanсe
        ? false
        : currentRelevanсe === false
        ? null
        : true
      const data = this.questions
      data[id].isRelevant = nextRelevance
      this.editQuestionnaire({ id: this.questionnaire.id, data })
    },
  },
}
</script>

<style scoped>
.is-relevant-area--avaliable {
  cursor: pointer;
  border: 1px dashed transparent;
  transition: background-color 0.15s ease;
}
.is-relevant-area--avaliable:hover {
  border: 1px dashed #ccc;
}
.v-data-table >>> td {
  position: relative;
}
.is-relevant-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: 'pointer';
  display: flex;
  padding: 0 16px;
  justify-content: flex-start;
  align-items: center;
}
</style>
