<template>
  <v-dialog
    v-model="show"
    max-width="620"
    @click:outside="closeDialog"
    ref="dialog1"
  >
    <v-card>
      <v-card-title
        >{{ actionName }} карточку клинической рекоммендации</v-card-title
      >
      <v-card-text>
        <v-text-field
          label="Название"
          v-model="clinRec.name"
          :rules="[rules.required]"
          ref="name"
        ></v-text-field>
        <v-select
          label="Возрастная категория"
          :items="ages"
          v-model="clinRec.age_category"
        />
        <v-select
          v-if="isProfileEditable"
          label="Профиль"
          :items="profiles"
          v-model="clinRec.profile"
        />
        <div v-if="!isProfileEditable" class="mb-5">
          <v-select
            label="Профиль"
            disabled
            hide-details
            :items="profiles"
            v-model="clinRec.profile"
          />
          <div>
            Редактировать профиль можно только если у КР нет анкет экспертов
          </div>
        </div>
        <v-text-field
          label="Код МКБ"
          v-model="clinRec.mkb10_codes"
          :rules="[rules.required]"
          ref="mkb"
        ></v-text-field>
        <v-text-field
          label="Внешний ID"
          v-model="clinRec.external_id"
          :rules="[rules.required]"
          ref="extId"
        ></v-text-field>
        <SelectOwner v-if="isAdmin" v-model="clinRec.user_owner_id" />
        <v-textarea label="Комментрий" v-model="clinRec.comment"> </v-textarea>
        <SelectDate v-model="clinRec.time_deadline" :minDate="minDate" />
        <SelectFiles
          ref="file"
          :files="clinRec.files"
          :stagedFiles="clinRec.stagedFiles"
          @files="onFilesChange"
          @staged-files="onStagedFilesChange"
          :edit="edit"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onAdd" color="primary">Сохранить</v-btn>
        <v-btn @click="closeDialog">Отменить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectDate from '@/components/dialogs/SelectDate.vue'
import SelectFiles from '@/components/dialogs/SelectFiles.vue'
import SelectOwner from '@/components/dialogs/SelectOwner.vue'
import { NOW_PLUS_7D, displayDate } from '@/components/helpers'
import { ONCOLOGY, PROFILES } from '@/store/const/profiles'

export default {
  components: {
    SelectDate,
    SelectFiles,
    SelectOwner,
  },
  data() {
    return {
      clinRec: {},
      CLINREC_TEMPLATE: {
        files: [],
        time_deadline: NOW_PLUS_7D,
        stagedFiles: [],
        name: '',
        age_category: 'взрослые',
        profile: ONCOLOGY,
        mkb10_codes: '',
        external_id: '',
        user_owner_id: null,
        comment: '',
      },
      ages: ['взрослые', 'дети', 'взрослые и дети'],
      profiles: PROFILES,
      refsForValidate: ['name', 'mkb', 'extId', 'file'],
      rules: {
        required: v => !!v || 'Обязательное поле',
      },
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      require: false,
    },
  },
  computed: {
    ...mapGetters([
      'getLoggedUserFio',
      'getLoggedUserRole',
      'getLoggedUserId',
      'getAnalyst',
      'isAdmin',
      'isAnalyst',
    ]),
    isProfileEditable() {
      // Можно редактировать поле при создании карточки
      // Можно редактировать поле при редактриовании карточки, но только если нет экспертов
      return !this.edit || !this.item.experts.length
    },
    actionName() {
      return this.edit ? 'Редактировать' : 'Добавить'
    },
    minDate() {
      return this.edit
        ? displayDate(this.item.time_create)
        : displayDate(new Date())
    },
  },
  created() {
    this.resetClinRecObj()
  },
  watch: {
    item() {
      if (this.item) {
        this.clinRec = { ...this.item }
        this.clinRec.files = this.item.files.slice()
        this.clinRec.stagedFiles = []
        this.clinRec.time_deadline = displayDate(this.clinRec.time_deadline)
      }
    },
  },
  methods: {
    ...mapActions(['addClinRec', 'editClinRec', 'addClinRegFiles']),
    resetClinRecObj() {
      this.clinRec = { ...this.CLINREC_TEMPLATE }
      this.clinRec.files = []
      this.clinRec.stagedFiles = []
    },
    closeDialog() {
      this.refsForValidate.forEach(ref => this.$refs[ref].reset())
      this.resetClinRecObj()
      this.$emit('close-dialog')
    },
    onFilesChange(value) {
      this.clinRec.files = value
    },
    onStagedFilesChange(value) {
      this.clinRec.stagedFiles = value
    },
    validate() {
      let totalValid = true
      for (let refKey of this.refsForValidate) {
        this.$refs[refKey].validate()
        if (!this.$refs[refKey].valid) {
          this.$refs[refKey].focus()
          this.$refs[refKey].blur()
          totalValid = false
          break
        }
      }
      return totalValid
    },
    onAdd() {
      if (!this.validate()) return
      if (this.isAnalyst) {
        this.clinRec.user_owner_id = this.getLoggedUserId
      }
      if (this.edit) {
        const payload = { ...this.clinRec }
        if (!this.isProfileEditable) delete payload.profile
        // Уточнить возможно тут не нужен id, т.к. он уже есть в объекте
        this.editClinRec({ ...payload, id: this.item.id })
      } else {
        this.addClinRec({ ...this.clinRec })
      }
      this.closeDialog()
    },
  },
}
</script>
