<template>
  <v-dialog v-model="show" max-width="600" @click:outside="closeDialog">
    <v-card>
      <v-card-title>Сбросить пароль</v-card-title>
      <!-- Запросить новый токен -->
      <v-card-text v-if="!newToken">
        <div>
          Вы собираетесь сбросить пароль для пользователя
          <span class="font-weight-bold">{{ item.fio }}</span
          >. Это опасное действие. Не делайте этого, если пользователь не просил
          вас об этом или вы не понимаете зачем это делаете.
        </div>
        <v-checkbox
          v-model="confirm"
          color="error"
          value="error"
          label="Понимаю и хочу продолжить"
        ></v-checkbox>
        <v-alert v-if="error" dense outlined type="error">{{ error }}</v-alert>
      </v-card-text>
      <v-card-actions v-if="!newToken">
        <v-btn @click="resetPassword" color="error" :disabled="!confirm"
          >Сбросить</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" min-width="100">Отмена</v-btn>
      </v-card-actions>

      <!-- Скопировать токен после получения -->
      <v-card-text v-if="newToken && !copyed">
        Успешно! Теперь скопируйте ссылку
        <span class="font-weight-bold">{{ link }}</span>
        и отправьте её письмом на адрес:
        <span class="font-weight-bold"> {{ item.email }}</span>
      </v-card-text>
      <v-card-actions v-if="newToken && !copyed">
        <v-btn @click="copyLink" color="primary"
          >Скопировать ссылку и текст письма</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" min-width="100">Закрыть</v-btn>
      </v-card-actions>

      <!-- Успех -->
      <v-card-text v-if="newToken && copyed">
        Адрес почты, ссылка и текст сообщения для пользователя скопированы в
        буфер обмена</v-card-text
      >
      <v-card-actions v-if="newToken && copyed">
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" min-width="100">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      confirm: false,
      newToken: '',
      copyed: false,
      error: '',
    }
  },
  computed: {
    link() {
      return `https://vimis-expert.corp.rosmedex.ru/reset-password/${this.newToken}`
    },
    fullText() {
      return `
Это письмо нужно отправить на ${this.item.email}
-----------
Здравствуйте!
Это ваша личная ссылка для смены пароля
${this.link}

Инструкция:
1. Перейдите по ссылке
2. Придумайте новый пароль
3. Введите его два раза
4. Нажмите «Установить пароль»

Если все успешно, то вас перенаправит на страницу входа. Введите ваш адрес электронной почты и новый пароль.`
    },
  },
  methods: {
    closeDialog() {
      this.confirm = false
      this.copyed = false
      this.newToken = ''
      this.$emit('close-reset')
    },
    copyLink() {
      navigator.clipboard
        .writeText(this.fullText)
        .then(() => (this.copyed = true))
    },
    async resetPassword() {
      this.error = ''
      try {
        let { data } = await axios.put(
          `/api/user/${this.item.id}/generate-reset-password-token`
        )
        this.newToken = data.reset_password_token
      } catch {
        this.error = 'Ошибка сброса пароля!'
      }
    },
  },
}
</script>

<style scoped>
.v-card__text {
  min-height: 162px;
}
</style>
