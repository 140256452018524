<template>
  <div>
    <div>
      <span class="mr-2">{{ user.roleName }}:</span>
      <span class="font-weight-bold">{{ user.fio }}</span>
    </div>

    <div v-if="user.showClinRecAmount && user.amountClinRecs">
      <span class="mr-2">КР назначено:</span>
      <span class="font-weight-bold">{{ user.amountClinRecs }}</span>
    </div>

    <div v-if="user.showClinRecAmount && !user.amountClinRecs">
      <span class="red--text">КР не назначены</span>
    </div>

    <div v-if="user.email">
      <span class="mr-2">Электроная почта:</span>
      <span class="font-weight-bold">{{ user.email }}</span>
    </div>
    <div v-if="user.phone">
      <span class="mr-2">Телефон:</span>
      <span class="font-weight-bold">{{ user.phone }}</span>
    </div>
    <div v-if="user.showOwner && user.ownerFIO">
      <span class="mr-2">Ответственный за {{ user.dativRoleName }}:</span>
      <span class="font-weight-bold">{{ user.ownerFIO }}</span>
    </div>
    <div v-if="user.comment">
      <span class="mr-2">Комментарий:</span>
      <span>{{ user.comment }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>
