<template>
  <v-dialog v-model="dialog" max-width="1280" @click:outside="onCancel">
    <v-card>
      <v-card-title>{{ clinRec.name }}</v-card-title>
      <v-card-subtitle>Ответы эксперта: {{ expert.fio }}</v-card-subtitle>
      <v-card-actions>
        <v-btn @click="onCancel">Назад</v-btn>
      </v-card-actions>
      <v-card-text>
        <ReadyAnswersWithSelect :expertId="expert.id" :clinRecId="clinRec.id" />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onCancel">Назад</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ReadyAnswersWithSelect from '@/components/cards/ReadyAnswersWithSelect.vue'

export default {
  components: { ReadyAnswersWithSelect },
  data() {
    return {
      dialog: false,
      expert: { id: null, fio: null },
      clinRec: { id: null, name: null },
    }
  },
  methods: {
    open(expert, clinRec) {
      this.dialog = true
      this.expert = expert
      this.clinRec = clinRec
    },
    onCancel() {
      this.dialog = false
    },
  },
}
</script>
