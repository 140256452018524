<template>
  <div>
    <v-data-table
      hide-default-footer
      v-if="clinRecs.length"
      :headers="headers"
      :items="filteredClinRecs"
      disable-pagination
      show-expand
      single-expand
      item-key="id"
      :search="getFilterText"
      @item-expanded="ReloadAll()"
    >
      <!-- Кнопки действий -->
      <template v-slot:[`item.controls`]="{ item }">
        <div v-if="item.editorAccess" class="d-flex controls">
          <v-btn
            @click="onOpenDialogToEdit(item)"
            fab
            small
            depressed
            color="transparent"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn
            @click="onOpenDeleteDialog(item)"
            fab
            small
            depressed
            color="transparent"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </div>
      </template>

      <!-- Отображение иконки гтовности -->
      <template v-slot:[`item.isTotalDone`]="{ item }">
        <v-icon
          v-if="item.isTotalDone"
          color="green"
          title="Все эксперты ответили"
        >
          mdi-checkbox-marked-circle
        </v-icon>
      </template>

      <!-- Не отображаем полный спсиок кодов, но он нужны для работы поиска -->
      <template v-slot:[`item.mkb10_codes`]=""> &nbsp; </template>

      <!-- Раскрывашка со списком клинрегов -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ClinRecCard :clinRec="item" class="mt-6 ml-4 body-1" />
          <SelectExpertList
            :experts="experts"
            :appointedExperts="item.experts"
            :clinRec="item"
          />
          <ExportWord :clinRec="item" class="ma-4 body-1" />
        </td>
      </template>
    </v-data-table>

    <ClinRecDialog
      :show="addDialog.show"
      :edit="addDialog.edit"
      :item="addDialog.item"
      @close-dialog="onCloseDialog"
    />

    <DeleteDialog
      title="Удалить?"
      :description="deleteDialogDescription"
      deleteText="Удалить"
      cancelText="Отменить"
      ref="dialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SelectExpertList from '@/components/admin/SelectExpertList.vue'
import ExportWord from '@/components/admin/ExportWord.vue'
import ClinRecDialog from '@/components/dialogs/ClinRecDialog.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import ClinRecCard from '@/components/cards/ClinRecCard.vue'

export default {
  name: 'ClinRecsList',
  components: {
    SelectExpertList,
    ClinRecDialog,
    DeleteDialog,
    ClinRecCard,
    ExportWord,
  },
  props: {
    clinRecs: {
      type: Array,
      required: true,
    },
    experts: {
      type: Array,
      required: true,
    },
    hideForAnalyst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteDialogItem: {},
      addDialog: {
        show: false,
        edit: false,
        item: {},
      },
    }
  },
  methods: {
    ...mapActions([
      'deleteClinRec',
      'loadUsers',
      'loadClinRecs',
      'loadQuestionnaires',
      'loadFiles',
    ]),
    onOpenDialogToEdit(item) {
      this.addDialog.item = { ...item }
      this.addDialog.edit = true
      this.addDialog.show = true
    },
    onCloseDialog() {
      this.addDialog.show = false
    },
    // В идеале на раскрытие строки загружать не все а только нужный item
    ReloadAll() {
      this.loadUsers()
      this.loadClinRecs()
      this.loadQuestionnaires()
      this.loadFiles()
    },
    async onOpenDeleteDialog(item) {
      this.deleteDialogItem = item
      if (await this.$refs.dialog.open()) {
        this.deleteClinRec(this.deleteDialogItem.id)
      }
    },
  },
  computed: {
    ...mapGetters(['getFilterText', 'getFilterProfile']),
    headers() {
      const headers = [
        { text: '', value: 'data-table-expand', sortable: false },
        { text: 'ID', value: 'external_id', width: 70 },
        {
          text: '',
          value: 'isTotalDone',
          sortable: false,
          width: 16,
          cellClass: 'pa-0 text-end',
        },
        {
          text: `${
            this.hideForAnalyst ? 'Мои к' : 'К'
          }линические рекоммендации (${
            this.getFilterProfile || 'Все профили'
          }: ${this.filteredClinRecs.length})`,
          value: 'name',
        },
        {
          text: 'Отв. аналитик',
          value: 'analyst',
          width: 170,
          hideForAnalyst: true,
        },
        { text: 'Возрастная группа', value: 'age_category', width: 160 },
        { text: '', value: 'controls', sortable: false, width: 120 },
        { text: '', value: 'mkb10_codes', sortable: false, width: 0 },
      ]
      return this.hideForAnalyst
        ? headers.filter(h => !h.hideForAnalyst)
        : headers
    },
    deleteDialogDescription() {
      return `Действительно удалить «${this.deleteDialogItem.name}»? Это действие нельзя отменить. Пропадут все заполненые анкеты всех
        экспертов по этой КР.`
    },
    filteredClinRecs() {
      return this.getFilterProfile
        ? this.clinRecs.filter(cr => cr.profile === this.getFilterProfile)
        : this.clinRecs
    },
  },
}
</script>

<style scoped>
.v-data-table >>> tr .controls {
  display: none !important;
}
.v-data-table >>> tr:hover .controls {
  display: flex !important;
}
</style>
