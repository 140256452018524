<template>
  <div>
    <div class="font-weight-bold">КР за которые отвечает аналитик:</div>
    <ul>
      <li v-for="cr in filteredClinRecs" :key="cr.id">
        {{ cr.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    clinRecs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredClinRecs() {
      return this.clinRecs.filter(cr => this.user.clinRecs.includes(cr.id))
    },
  },
}
</script>
