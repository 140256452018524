<template>
  <div class="d-flex align-center">
    <v-select
      v-if="isFilter"
      :items="profiles"
      :value="getFilterProfile"
      label="Фильтр по профилю КР"
      @input="handleFilterInput"
      clearable
      single-line
      hide-details
      class="pt-0 mt-0 mr-4 filter"
      ref="filter"
    />
    <v-text-field
      :value="getFilterText"
      @input="handleInput"
      append-icon="mdi-magnify"
      :label="labelText"
      single-line
      hide-details
      class="pt-0 mt-0 mr-4"
      min-width="260"
    ></v-text-field>

    <v-btn @click="onClick" min-width="260" v-if="isNotObserver">
      <v-icon>mdi-plus</v-icon>
      <span>{{ buttonText }}</span>
    </v-btn>

    <ClinRecDialog :show="addClinRecShow" @close-dialog="onCloseDialog" />

    <UserDialog :show="addUserShow" @close-dialog="onCloseDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClinRecDialog from '@/components/dialogs/ClinRecDialog.vue'
import UserDialog from '@/components/dialogs/UserDialog.vue'
import { OBSERVER } from '@/store/const/userRoles'
import { TAB_USERS } from '@/store/const/adminTabs'
import { PROFILES } from '@/store/const/profiles'

export default {
  components: {
    UserDialog,
    ClinRecDialog,
  },
  props: ['tabName'],
  data() {
    return {
      addClinRecShow: false,
      addUserShow: false,
      profiles: PROFILES,
    }
  },
  computed: {
    ...mapGetters([
      'getFilterText',
      'getFilterProfile',
      'getLoggedUserRole',
      'getAnalyst',
      'getLoggedUserId',
      'isAnalyst',
    ]),
    isFilter() {
      return this.tabName !== TAB_USERS
    },
    labelText() {
      if (this.isAnalyst) {
        return this.tabName === TAB_USERS ? 'Поиск по экспертам' : 'Поиск по КР'
      } else {
        return this.tabName === TAB_USERS
          ? 'Поиск по пользователям'
          : 'Поиск по КР'
      }
    },
    buttonText() {
      if (this.isAnalyst) {
        return this.tabName === TAB_USERS ? 'Добавить эксперта' : 'Добавить КР'
      } else {
        return this.tabName === TAB_USERS
          ? 'Добавить пользователя'
          : 'Добавить КР'
      }
    },
    isNotObserver() {
      return this.getLoggedUserRole !== OBSERVER
    },
  },
  methods: {
    ...mapActions(['setFilterText', 'setFilterProfile']),
    handleInput(text) {
      this.setFilterText(text)
    },
    handleFilterInput(val) {
      if (!val) {
        this.$refs.filter.blur()
      }
      this.setFilterProfile(val)
    },
    onClick() {
      this.setFilterText('')
      this.tabName === TAB_USERS ? this.AddUser() : this.AddClinRec()
    },
    AddClinRec() {
      this.addClinRecShow = true
    },
    AddUser() {
      this.addUserShow = true
    },
    onCloseDialog() {
      this.addClinRecShow = false
      this.addUserShow = false
    },
  },
}
</script>

<style scoped>
.filter {
  width: 260px;
}
</style>
