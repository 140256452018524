<template>
  <div>
    <v-data-table
      hide-default-footer
      v-if="experts.length"
      :headers="headers"
      :items="experts"
      disable-pagination
      show-expand
      item-key="id"
      :search="getFilterText"
    >
      <!-- Кнопки действий -->
      <template v-slot:[`item.controls`]="{ item }">
        <div class="d-flex controls" v-if="item.editorAccess">
          <v-btn
            @click="onOpenDialogToEdit(item)"
            fab
            small
            depressed
            color="transparent"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn
            @click="onOpenDeleteDialog(item)"
            fab
            small
            depressed
            color="transparent"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </div>
      </template>

      <!-- Раскрывашка со списком клинрегов и данными эксперта -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <UserCard :user="item" class="mt-6 ml-4 mb-4 body-1" />
          <SelectCRList
            v-if="item.canAnswer"
            :clinRecs="clinRecs"
            :expert="item"
          />
          <!-- список нужен той роли которая не отвечает на вопросы, но на которой есть КР, если есть -->
          <ClinRecList
            class="ml-4 mb-4 body-1"
            v-if="!item.canAnswer && item.amountClinRecs"
            :user="item"
            :clinRecs="clinRecs"
          />
        </td>
      </template>
    </v-data-table>

    <AddExpertDialog
      :show="addDialog.show"
      :edit="addDialog.edit"
      :item="addDialog.item"
      @close-dialog="onCloseDialog"
      @reset-password="onResetPassword"
    />

    <ResetPasswordDialog
      :show="resetDialog.show"
      :item="resetDialog.item"
      @close-reset="onCloseReset"
    />

    <DeleteDialog
      title="Удалить?"
      :description="deleteDialogDescription"
      deleteText="Удалить"
      cancelText="Отменить"
      ref="dialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SelectCRList from '@/components/admin/SelectCRList.vue'
import AddExpertDialog from '@/components/dialogs/UserDialog.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import UserCard from '@/components/cards/UserCard.vue'
import ClinRecList from '@/components/cards/ClinRecList.vue'
import ResetPasswordDialog from '@/components/dialogs/ResetPasswordDialog.vue'

export default {
  data() {
    return {
      deleteDialogItem: {},
      resetDialog: {
        show: false,
        item: {},
      },
      addDialog: {
        show: false,
        edit: false,
        item: {},
      },
    }
  },
  props: {
    clinRecs: {
      type: Array,
      required: true,
    },
    experts: {
      type: Array,
      required: true,
    },
  },
  components: {
    SelectCRList,
    AddExpertDialog,
    DeleteDialog,
    UserCard,
    ResetPasswordDialog,
    ClinRecList,
  },
  computed: {
    ...mapGetters(['getFilterText', 'isAnalyst']),
    deleteDialogDescription() {
      return `Действительно удалить «${this.deleteDialogItem.fio}»? Это действие нельзя отменить. Пропадут все заполненые анкеты эксперта.`
    },
    headers() {
      const userRoleText = this.isAnalyst ? 'Эксперты' : 'Пользователи'
      const headers = [
        { text: '', value: 'data-table-expand', sortable: false },
        { text: userRoleText, value: 'fio' },
        { text: 'КР назначено', value: 'amountClinRecs' },
        { text: 'Роль', value: 'roleName', hideForAnalyst: true },
        { text: '', value: 'controls', sortable: false, width: 120 },
      ]
      return this.isAnalyst ? headers.filter(h => !h.hideForAnalyst) : headers
    },
  },
  methods: {
    ...mapActions(['deleteUser']),
    onOpenDialogToEdit(item) {
      this.addDialog.item = { ...item }
      this.addDialog.edit = true
      this.addDialog.show = true
    },
    onCloseDialog() {
      this.addDialog.show = false
    },
    onResetPassword(item) {
      this.resetDialog.show = true
      this.resetDialog.item = item
    },
    onCloseReset() {
      this.resetDialog.show = false
    },
    async onOpenDeleteDialog(item) {
      this.deleteDialogItem = item
      if (await this.$refs.dialog.open()) {
        this.deleteUser(this.deleteDialogItem.id)
      }
    },
  },
}
</script>

<style scoped>
.v-data-table >>> tr .controls {
  display: none !important;
}
.v-data-table >>> tr:hover .controls {
  display: flex !important;
}
</style>
