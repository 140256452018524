var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.clinRecs.length)?_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.filteredClinRecs,"disable-pagination":"","show-expand":"","single-expand":"","item-key":"id","search":_vm.getFilterText},on:{"item-expanded":function($event){return _vm.ReloadAll()}},scopedSlots:_vm._u([{key:"item.controls",fn:function(ref){
var item = ref.item;
return [(item.editorAccess)?_c('div',{staticClass:"d-flex controls"},[_c('v-btn',{attrs:{"fab":"","small":"","depressed":"","color":"transparent"},on:{"click":function($event){return _vm.onOpenDialogToEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"fab":"","small":"","depressed":"","color":"transparent"},on:{"click":function($event){return _vm.onOpenDeleteDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]}},{key:"item.isTotalDone",fn:function(ref){
var item = ref.item;
return [(item.isTotalDone)?_c('v-icon',{attrs:{"color":"green","title":"Все эксперты ответили"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e()]}},{key:"item.mkb10_codes",fn:function(){return [_vm._v("   ")]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ClinRecCard',{staticClass:"mt-6 ml-4 body-1",attrs:{"clinRec":item}}),_c('SelectExpertList',{attrs:{"experts":_vm.experts,"appointedExperts":item.experts,"clinRec":item}}),_c('ExportWord',{staticClass:"ma-4 body-1",attrs:{"clinRec":item}})],1)]}}],null,true)}):_vm._e(),_c('ClinRecDialog',{attrs:{"show":_vm.addDialog.show,"edit":_vm.addDialog.edit,"item":_vm.addDialog.item},on:{"close-dialog":_vm.onCloseDialog}}),_c('DeleteDialog',{ref:"dialog",attrs:{"title":"Удалить?","description":_vm.deleteDialogDescription,"deleteText":"Удалить","cancelText":"Отменить"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }