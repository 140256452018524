<template>
  <div>
    <span class="tip" @click="exportWord">Выгрузить ответы в Word</span>
  </div>
</template>

<script>
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
} from 'docx'
import { saveAs } from 'file-saver'
import timestamp from 'time-stamp'

import { questionsList } from '@/store/const/questionsList'
import { mapGetters } from 'vuex'
import { ANSWERS } from '@/store/const/answers'
import { isQuestionDone, russianDate } from '@/components/helpers'
import {
  questionnaireStatuses,
  getStatus,
} from '@/store/const/questionnaireStatuses'

export default {
  props: ['clinRec'],
  data() {
    return {
      // Размер в полу-пунктах. Например, 'defaultSize: 22' это 11-й шрифт в Word.
      defaultSize: 22,
    }
  },
  computed: {
    ...mapGetters(['getQuestionnaires', 'getUsers']),
  },
  methods: {
    createParagraph(text, options = {}) {
      return new Paragraph({
        ...options,
        children: [
          new TextRun({
            text: `${text}`,
            size: this.defaultSize,
          }),
        ],
      })
    },
    createParagraphWithSpacing(text, after = 200, before = 0) {
      return this.createParagraph(text, {
        spacing: {
          after,
          before,
        },
      })
    },
    createParagraphSignature(text) {
      return this.createParagraph(text, {
        alignment: AlignmentType.RIGHT,
      })
    },
    createParagraphTitle(text) {
      return new Paragraph({
        spacing: {
          after: 200,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: `${text}`,
            size: this.defaultSize,
            bold: true,
          }),
        ],
      })
    },
    createCellsInRow(row) {
      const margins = {
        top: 50,
        bottom: 50,
        left: 50,
        right: 50,
      }
      return [
        new TableCell({
          margins,
          children: [this.createParagraph(row.order)],
        }),
        new TableCell({
          margins,
          children: [this.createParagraph(row.question)],
        }),
        new TableCell({
          margins,
          children: [this.createParagraph(row.answer)],
        }),
        new TableCell({
          margins,
          children: [this.createParagraph(row.comment)],
        }),
      ]
    },
    createTableHeaderRow() {
      const headers = {
        order: '№',
        question: 'Вопрос',
        answer: 'Ответ эксперта',
        comment: 'Комментарий',
      }
      return new TableRow({ children: this.createCellsInRow(headers) })
    },
    createTable(tableData) {
      return new Table({
        rows: [
          this.createTableHeaderRow(),
          ...tableData.map(
            row =>
              new TableRow({
                children: [...this.createCellsInRow(row)],
              })
          ),
        ],
      })
    },
    blockHeader(expertFIO) {
      const text = 'Анкета Эксперта по работе с клинической рекомендацией:'
      return [
        this.createParagraphWithSpacing(text),
        this.createParagraphTitle(this.clinRec.name),
        this.createParagraphWithSpacing(`Ответы эксперта: ${expertFIO}`),
      ]
    },
    createStateParagraph(answers, wasSent) {
      const isAnswersDone = answers.map(a => isQuestionDone(a))
      const amountDone = isAnswersDone.reduce((a, b) => a + b, 0)
      const text =
        questionnaireStatuses[getStatus(wasSent, amountDone, answers.length)]
          .textForWord
      return this.createParagraphWithSpacing(text, 200, 200)
    },
    blockAnswers(answers, wasSent) {
      const readableAnswers = this.getReadableAnswers(answers)
      return [
        this.createTable(readableAnswers),
        this.createStateParagraph(answers, wasSent),
      ]
    },
    blockDeadline(sendTime, wasSent) {
      const nowLocalDate = russianDate(new Date().toString())
      const localDeadline = russianDate(this.clinRec.time_deadline)

      if (!sendTime) {
        return [
          this.createParagraph(`Дата выгрузки ответов: ${nowLocalDate}`),
          this.createParagraphWithSpacing(`Дедлайн по КР: ${localDeadline}`),
        ]
      }

      const localSendTime = russianDate(sendTime)

      let text
      if (wasSent) text = 'Была отправлена экспертом'
      else if (wasSent === null) text = 'Дата последнего ответа'
      else text = 'Дата последнего действия эксперта'

      return [
        this.createParagraph(`Дата выгрузки ответов: ${nowLocalDate}`),
        this.createParagraph(`Дедлайн по КР: ${localDeadline}`),
        this.createParagraphWithSpacing(`${text}: ${localSendTime}`),
      ]
    },
    blockSignature() {
      return [
        this.createParagraph('Начальник отдела методологии'),
        this.createParagraph('информатизации здравоохранения'),
        this.createParagraph('ФГБУ ЦЭККМП Минздрава России'),
        this.createParagraphSignature('Федяев Д.В.'),
      ]
    },
    oneExpertAnswerPage({ expertFIO, sendTime, answers, wasSent }) {
      return {
        children: [
          ...this.blockHeader(expertFIO),
          ...this.blockAnswers(answers, wasSent),
          ...this.blockDeadline(sendTime, wasSent),
          ...this.blockSignature(),
        ],
      }
    },
    getUserFIObyID(id) {
      return this.getUsers.find(u => u.id === id).fio || 'Не найден'
    },
    getAnswerText(answerId) {
      return answerId ? ANSWERS.find(a => a.id === answerId).text : 'Нет ответа'
    },
    getQuestionText(questionId) {
      return questionsList[this.clinRec.profile].find(q => q.id === questionId)
        .title
    },
    getReadableAnswers(answers) {
      return answers.map(a => ({
        order: a.id + 1,
        question: this.getQuestionText(a.id),
        answer: this.getAnswerText(a.answerId),
        comment: a.comment ? 'Есть' : '—',
      }))
    },
    filterQuestionnaires() {
      return this.getQuestionnaires
        .filter(q => q.cr_id === this.clinRec.id)
        .map(d => ({
          expertFIO: this.getUserFIObyID(d.user_id),
          wasSent: d.was_sent,
          sendTime: d.time_edit,
          answers: d.data,
        }))
    },
    async exportWord() {
      const doc = new Document({
        sections: this.filterQuestionnaires().map(expertAnswer =>
          this.oneExpertAnswerPage(expertAnswer)
        ),
      })

      const timeStamp = timestamp('YYYYMMDD-HHmm')
      Packer.toBlob(doc).then(blob =>
        saveAs(blob, `${timeStamp}_Ответы экспертов_${this.clinRec.name}`)
      )
    },
  },
}
</script>

<style scoped>
.tip {
  border: 1px none rgba(0, 0, 0, 0.87);
  border-bottom-style: dashed;
  cursor: pointer;
  opacity: 1;
  transition: opacity ease 0.2s;
}
.tip:hover {
  opacity: 0.5;
}
</style>
