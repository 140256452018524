<template>
  <v-dialog v-model="dialog" max-width="600" @click:outside="onCalcel">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ description }}</v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="onDelete">{{ deleteText }}</v-btn>
        <v-btn @click="onCalcel">{{ cancelText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      resolve: () => {},
      reject: () => {},
      dialog: false,
    }
  },
  props: {
    title: {
      type: String,
      default: 'Удалить?',
    },
    description: {
      type: String,
      default: 'Действительно удалить это?',
    },
    deleteText: {
      type: String,
      default: 'Удалить',
    },
    cancelText: {
      type: String,
      default: 'Отмена',
    },
  },
  methods: {
    open() {
      return new Promise((res, rej) => {
        this.dialog = true
        this.resolve = res
        this.reject = rej
      })
    },
    onDelete() {
      this.dialog = false
      this.resolve(true)
    },
    onCalcel() {
      this.dialog = false
      this.resolve(false)
    },
  },
}
</script>
