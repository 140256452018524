<template>
  <div>
    <div class="d-flex">
      <v-switch
        v-model="showAppointed"
        label="Показать только назначенные"
        class="mr-8"
      ></v-switch>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Искать КР для этого эксперта"
        single-line
        hide-details
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="displayClinRecs"
      hide-default-footer
      disable-pagination
      item-key="id"
      show-select
      :search="search"
    >
      <!-- Убрал чекбокс одновременного выбора всех строк -->
      <template v-slot:[`header.data-table-select`]=""> &nbsp; </template>

      <!-- Создаю чекбоксы вручную и без привязки к v-model чтобы отслеживать клики и обрабатывать их самостоятельно -->
      <template v-slot:[`item.data-table-select`]="{ item }"
        ><v-checkbox
          v-if="item.editorAccess"
          :input-value="isAppointed(item)"
          readonly
          @click="onSelectClinRec(item)"
      /></template>
    </v-data-table>

    <DeleteDialog
      title="Отменить назначение?"
      description="Если эксперт уже начал работу над анкетой его ответы пропадут."
      deleteText="Да, отменить"
      cancelText="Оставить"
      ref="deleteDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'

export default {
  data() {
    return {
      search: '',
      showAppointed: false,
      headers: [{ text: 'Выберете КР для этого эксперта', value: 'name' }],
    }
  },
  props: {
    clinRecs: {
      type: Array,
      required: true,
    },
    expert: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.showAppointed = this.expert.clinRecs.length
  },
  components: {
    DeleteDialog,
  },
  computed: {
    ...mapGetters(['getQuestionnaires']),
    displayClinRecs() {
      const result = this.clinRecs.slice()
      return this.showAppointed
        ? result.filter(cr => this.expert.clinRecs.includes(cr.id))
        : result
    },
  },
  methods: {
    ...mapActions(['addQuestionnaire', 'deleteQuestionnaire']),
    onSelectClinRec(item) {
      if (this.isAppointed(item)) {
        this.cancelAppointClinReg(item.id, this.expert.id)
      } else {
        this.appointClinReg(item.id, this.expert.id, item.questionnairyLength)
      }
    },
    isAppointed(item) {
      return this.expert.clinRecs.includes(item.id)
    },
    appointClinReg(cr_id, user_id, length) {
      this.addQuestionnaire({ cr_id, user_id, length })
    },
    async cancelAppointClinReg(crId, userId) {
      if (await this.$refs.deleteDialog.open()) {
        const questionnaire = this.getQuestionnaires.find(
          q => q.cr_id === crId && q.user_id === userId
        )
        this.deleteQuestionnaire(questionnaire.id)
      }
    },
  },
}
</script>
